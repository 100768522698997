(function () {
    "use strict";

    window.TEAN = window.TEAN || {};

    TEAN.selector = TEAN.selector || function(query) {
        query = query.trim();
        if ( !query || (typeof query != 'string')) return undefined;
        var ret = new function(query) {
            this.str = query;
            this.doms = document.querySelectorAll(query);
        }(query);
        return ret.doms.length ? ret : undefined;
    };

    TEAN.helper = TEAN.helper || new function() {
        this.get_page_number_from_url = function(param) {
            param = param || "page";
            return parseInt((new URL(window.location.href)).searchParams.get(param)) || 1;
        };

        this.find_ancestor_by_class = function(el, cls) {
            while ((el = el.parentElement) && !el.classList.contains(cls));
            return el;
        };

        this.check_element_visibility_in_window = function(elm, threshold, mode) {
            threshold = threshold || 0;
            mode = mode || 'visible';

            var rect = elm.getBoundingClientRect();
            var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            if (typeof threshold == 'string') {
                var temp_threshold = parseFloat(threshold);
                if (threshold.indexOf('%') > -1) {
                    temp_threshold = (temp_threshold / 100.0) * rect.height;
                }
                threshold = temp_threshold;
            }
            var above = rect.bottom - threshold < 0;
            var below = rect.top - viewHeight + threshold >= 0;

            return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
        };

        this.get_element_index = function(query, target) {
            var result = [];
            try {
                if (typeof query == 'string') {
                    result = document.querySelectorAll(query);
                } else if ((query instanceof NodeList) || (query instanceof HTMLCollection)) {
                    result = query;
                } else {
                    result = query.doms;
                }
            } catch(e) {
                return undefined;
            }
            var nodes = Array.prototype.slice.call(result);
            return nodes.indexOf(target);
        };
    };

    TEAN.tracker = TEAN.tracker || new function() {
        this.uid = "";

        var communicate = function(url, method, data, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function() {
                if(xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
                    if (callback) callback(xhr);
                }
            };
            var methodList = ['GET', 'POST'];
            if (methodList.indexOf(method.toUpperCase()) > -1) {
                xhr.open(method.toUpperCase(), url + '/' + TEAN.tracker.uid);
                try {
                    var CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').content;
                    xhr.setRequestHeader('X-CSRF-TOKEN', CSRF_TOKEN);
                    if (data) {
                        xhr.setRequestHeader('Content-type', 'application/json');
                        data = JSON.stringify(data);
                    }
                    xhr.send(data);
                } catch(e) {
                    ;
                }
            }
        };

        this.init = function() {
            // Temp solution for unique tracking
            communicate('/tean/tracker/init', 'GET', undefined, function(xhr) {
                TEAN.tracker.uid = JSON.parse(xhr.responseText)['uid'];
            });
        };

        this.event = new function() {
            this.eventMap = new function() {
                this.map = [];

                this.set = function(selector, event, getter, eventFn) {
                    selector = selector ? ((typeof selector == "string") ? selector : selector.str) : undefined;
                    if (this.map[selector] == undefined) {
                        this.map[selector] = {};
                    }
                    if ( !this.map[selector].hasOwnProperty(event)) {
                        this.map[selector][event] = {};
                    }
                    if ( !this.map[selector][event].hasOwnProperty(getter) &&
                        eventFn)
                    {
                        this.map[selector][event][getter] = eventFn;
                        return true;
                    }
                    return false;
                };

                this.get = function(selector, event, getter) {
                    selector = selector ? ((typeof selector == "string") ? selector : selector.str) : undefined;
                    try {
                        if (selector && event && getter) {
                            return this.map[selector][event][getter];
                        } else if (selector && event) {
                            return this.map[selector][event];
                        } else {
                            return this.map[selector];
                        }
                    } catch(e) {
                        return undefined;
                    }
                    return undefined;
                };

                this.remove = function(selector, event, getter) {
                    if (this.map[selector.str] != undefined) {
                        if (this.map[selector.str].hasOwnProperty(event)) {
                            if (this.map[selector.str][event].hasOwnProperty(getter)) {
                                delete this.map[selector.str][event][getter];
                                if (Object.keys(this.map[selector.str][event]).length === 0) {
                                    delete this.map[selector.str][event];
                                }
                                if (Object.keys(this.map[selector.str]).length === 0) {
                                    delete this.map[selector.str];
                                }
                                return true;
                            }
                        }
                    }
                    return false;
                };
            };

            this.eventFnGenerator = function(getter, callback, onlyTrusted) {
                onlyTrusted = onlyTrusted || false;
                return getter ? (function (e, callb) {
                    if (onlyTrusted && !e.isTrusted) return;
                    var cb = callb ? callb : callback;
                    var data = getter(e);
                    if (data != null) {
                        TEAN.tracker.send(data, cb ? cb.bind({'event' : e}) : undefined);
                    }
                    e.stopPropagation();
                }) : undefined;
            };

            this.attach = function(selector, event, getter, evtFn, onlyTrusted, evtCb) {
                onlyTrusted = onlyTrusted || false;
                if (typeof selector == 'string') {
                    selector = TEAN.selector(selector);
                }
                if ( !selector) return;
                var eventFn = this.eventMap.get(selector, event, getter);
                if ( !eventFn) {
                    try {
                        eventFn = typeof evtFn !== 'undefined' ? evtFn : TEAN.getter[getter];
                        if ( !eventFn) {
                            console.error("Not a valid event function");
                        }
                        eventFn = this.eventFnGenerator(eventFn, evtCb, onlyTrusted);
                        if (eventFn && this.eventMap.set(selector, event, getter, eventFn)) {
                            for (var i = 0; i < selector.doms.length; i++) {
                                selector.doms[i].addEventListener(event, eventFn);
                            }
                        }
                    } catch(e) {
                        console.error("Not a valid event function");
                    }
                } else {
                    this.detach(selector, event, getter);
                    this.attach(selector, event, getter, evtFn, onlyTrusted, evtCb);
                }
            };

            this.detach = function(selector, event, getter) {
                var eventFn = this.eventMap.get(selector, event, getter);
                if (eventFn && this.eventMap.remove(selector, event, getter)) {
                    for (var i = 0; i < selector.doms.length; i++) {
                        selector.doms[i].removeEventListener(event, eventFn);
                    }
                }
            };

            this.trigger = function(selector, event) {
                if (event instanceof Event || typeof event == 'string') {
                    if (typeof event == 'string'){
                        event = new Event(event, {'bubbles':true, 'cancelable':false});
                    }
                    var checker = this.eventMap.get(selector, event.type);
                    if (checker) {
                        selector.doms.forEach( function(element, index) {
                            element.dispatchEvent(event);
                        });
                    }
                }
            };
        };

        this.campaign = new function() {
            var loaded = false;
            var items = {};

            this.isLoaded = function() {
                return loaded;
            };

            this.load = function(callback) {
                communicate('/tean/tracker/get', 'GET', undefined, function(xhr) {
                    items = JSON.parse(xhr.responseText)['items'];
                    loaded = true;
                    if (callback) {
                        callback();
                    }
                });
            };

            this.start = function() {
                for (var i in items) {
                    var s = TEAN.selector(items[i]['query']);
                    TEAN.tracker.event.attach(s, items[i]['event'], items[i]['getter']);
                }
            };
        };

        this.send = function(args, cb) {
            if ( !TEAN.tracker.uid) {
                window.setTimeout(function (){
                    TEAN.tracker.send(args, cb);
                }, 250);
            } else {
                var handler = (function(arg) {
                    if (arg.length > 1) {
                        if (typeof arg[0] == 'string' && typeof arg[1] == 'string') {
                            var mapper = ['category', 'action', 'components', 'value'];
                            var dataToSend = {};
                            try {
                                for (var i = 0; i < Math.min(arg.length, mapper.length); i++) {
                                    dataToSend[mapper[i]] = arg[i];
                                }
                                communicate('/tean/tracker/send', 'POST', dataToSend, cb);
                            } catch(e) {
                                ;
                            }
                        }
                    }
                });

                if (Array.isArray(args)) {
                    if (Array.isArray(args[0])) {
                        args.forEach(function (ele){
                            if (Array.isArray(ele)) {
                                handler(ele);
                            }
                        });
                    } else {
                        handler(args);
                    }
                }
            }
        };
    };

    TEAN.module = TEAN.module || new function() {
        this.visible_on_scroll = function(args, evtHandler) {
            if ( !args.hasOwnProperty('query')) return ;
            var query = args['query'];
            var threshold = args.hasOwnProperty('threshold') ? args['threshold'] : undefined;
            var mode = args.hasOwnProperty('mode') ? args['mode'] : undefined;
            var getterName = args.hasOwnProperty('getterName') ? args['getterName'] : 'TEAN.module.visible_on_scroll.getter';
            var customEventName = args.hasOwnProperty('customEventName') ? args['customEventName'] : 'TEAN.module.visible_on_scroll.event';
            var autoDetach = args.hasOwnProperty('autoDetach') ? args['autoDetach'] : false;

            var customEvent = new Event(customEventName, {'bubbles':true, 'cancelable':false});
            var ele = TEAN.selector(query);
            var done = [];
            TEAN.tracker.event.attach(ele, customEventName, getterName, function (e){
                if (TEAN.helper.check_element_visibility_in_window(e.target, threshold, mode)) {
                    var index = TEAN.helper.get_element_index(ele, e.target);
                    if (done.indexOf(index) < 0) {
                        done.push(index);
                        if (evtHandler) {
                            if (autoDetach && (done.length == ele.doms.length)) TEAN.tracker.event.detach(ele, customEventName, getterName);
                            TEAN.tracker.send(evtHandler(e.target, index, ele.doms));
                        }
                    }
                }
            });
            TEAN.tracker.event.trigger(ele, customEvent);
            window.addEventListener('scroll', function (e) {
                if (e.isTrusted) {
                    TEAN.tracker.event.trigger(ele, customEvent);
                }
            });
        };
    };

    // Temp solution
    TEAN.tracker.init();
})();
